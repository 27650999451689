import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import theme from "./src/theme";
import "./src/styles/global.css";

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      {element}
    </ThemeProvider>
  </QueryClientProvider>
);
